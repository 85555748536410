import successIcon from "../../assets/images/icons/alert/success.svg";
import styles from "./SuccessCard.module.scss";

interface SuccessCardProps {
  title: string;
  message: string;
}

const SuccessCard = ({ title, message }: SuccessCardProps) => {
  const { container, icon, header, description } = styles;
  return (
    <div className={container}>
      <img src={successIcon} alt="successIcon" className={icon} />
      <h2 className={header}>{title}</h2>
      <p className={description}>{message}</p>
    </div>
  );
};

export default SuccessCard;
