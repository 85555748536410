import { useEffect, useState } from "react";

// DEPENDENCIES
import QRCode from "qrcode";
import { useLocation, useNavigate, useSearchParams } from "react-router-dom";
import { doc, getFirestore, onSnapshot, setDoc } from "@firebase/firestore";

//UTILS
import { useAppDispatch, useAppSelector } from "../../app/hooks";
import { logAnalyticsAndHotjarEvent } from "../../main";
import juridicalTemplate from "../../templates/allaria/juridical";
import physicalTemplate from "../../templates/allaria/physical";
import {
  onUpdatePrimaryButton,
  onUpdateSecondaryButton,
  onUpdateTertiaryButton,
} from "../../reducers/buttonsReducer";
import { updateToken } from "../../reducers/authReducer";
import { closeModal, openModal } from "../../reducers/modalReducer";
import {
  onUpdateOnClose,
  onUpdateQrImage,
  onUpdateScannedQr,
  onUpdateSuccessMessage,
} from "../../reducers/qrReducer";
import { onUpdateActiveTemplate } from "../../reducers/activeTemplateReducer";
import useForm from "../../hooks/useForm";
import useWindowWidth from "../../hooks/useWindowWidth";
import { qrStyle } from "../../utils";

//TYPES
import {
  ComponentProps,
  DniImageType,
  FirebaseDniData,
  OnboardingType,
} from "../../types";

// COMPONENTS
import {
  showToastMessage,
  ToastMessage,
} from "@almafintech/react-components/ToastMessage";
import { LoadingCircle } from "@almafintech/react-components/LoadingCircle";
import { Button } from "@almafintech/react-components/Button";
import { InputCopy } from "@almafintech/react-components/InputCopy";
import Layout from "../Layout/Layout";
import FormContainer from "../FormContainer/FormContainer";
import Container from "../Container/Container";
import UploadDniForm from "./UploadDniForm";
import QRModal from "../QRModal/QRModal";

// ICONS
import successIcon from "../../assets/images/close-window.svg";

import styles from "./UploadDniCard.module.scss";

const UploadDniPage = ({ formik }: ComponentProps) => {
  const {
    successContainer,
    loading,
    externalLink,
    secondaryButton,
    confirmButton,
    withQr,
    copyLinkContainer,
  } = styles;

  const { handleBack, handleContinue } = useForm(formik);
  const [success, setSuccess] = useState<boolean>(false);
  const [error, setError] = useState<boolean>(false);

  const [dniData, setDniData] = useState<FirebaseDniData>({
    scannedQR: false,
    frontDNI: "",
    backDNI: "",
  });

  const [isLoadingData, setIsLoadingData] = useState<boolean>(true);
  const [isLoadingButton, setIsLoadingButton] = useState<boolean>(false);

  const dispatch = useAppDispatch();

  const { draftUUID, formValues } = useAppSelector(
    (state) => state.onboardingData
  );
  const {
    onboardingType: savedTemplate,
    favicon,
    icon,
  } = useAppSelector((state) => state.activeTemplate);
  const { token: savedToken } = useAppSelector((state) => state.authentication);
  const { cuil } = useAppSelector((state) => state.renaper);

  const location = useLocation();
  const [searchParams] = useSearchParams();
  const navigate = useNavigate();

  const dimensions = useWindowWidth();
  const isMobile = dimensions.width < 768;

  const isJuridicalOnboarding = location.pathname.includes("empresarial");
  const isExternalLink = location.pathname.includes("dni");
  const continueWithQR = searchParams.get("withQR") === "true";
  const uploadedSuccessfully = searchParams.get("success") === "true";
  const urlDraftUUID = searchParams.get("uuid");
  const urlToken = searchParams.get("token");
  const urlTemplate = searchParams.get("template");
  const urlCuil = searchParams.get("cuil");

  const uuid = draftUUID || urlDraftUUID;
  const token = savedToken || urlToken;
  const savedCuil = formValues?.cuit;
  const cuit =  cuil || savedCuil || urlCuil;
  const onboardingType = savedTemplate || urlTemplate;

  const dniLink = `${window.location.origin}/dni?uuid=${uuid}&token=${token}&template=${onboardingType}&cuil=${cuit}`;

  const isSignatory = onboardingType === "SIGNATORY";

  const db = getFirestore();
  const dniRef = doc(
    db,
    isSignatory ? "dniImagesSignatory" : "dniImages",
    isSignatory ? `${uuid}-${formValues?.["email"]}` : uuid || " "
  );

  const disabledExternalLinkButton =
    !dniData.frontDNI || !dniData.backDNI || error;

  const disablePrimaryButton = dniData.scannedQR || disabledExternalLinkButton;

  const handleUpload = () => {
    if (continueWithQR) {
      setSuccess(true);
      setDoc(dniRef, { scannedQR: false }, { merge: true });
    } else {
      navigate(location.pathname.concat("?success=true"));
    }
  };

  const handleCopyLink = () => {
    navigator.clipboard.writeText(dniLink);
    showToastMessage("Link copiado al portapapeles.", {
      containerId: "layoutPage",
      type: "success",
    });
  };

  const handleOpenQr = () => {
    dispatch(onUpdateScannedQr(false));
    dispatch(openModal({ name: "modal-qr" }));
  };

  const dniStep = {
    name: "Identidad",
    subtitle: "Cargá fotos del frente y dorso de tu DNI.",
    step: 1,
  };

  const loadTemplate = async () => {
    if (urlTemplate === "PHYSICAL") {
      const template = await physicalTemplate();
      dispatch(onUpdateActiveTemplate(template));
    }

    if (urlTemplate === "JURIDICAL") {
      const template = await juridicalTemplate();
      dispatch(onUpdateActiveTemplate(template));
    }
  };

  // Get template to load favicon in mobile flow
  useEffect(() => {
    if (!savedTemplate) loadTemplate();
  }, []);

  useEffect(() => {
    if (favicon) {
      const newLink = document.createElement("link");
      newLink.rel = "icon";
      newLink.type = "image/png";
      newLink.href = favicon;
      document.head.appendChild(newLink);
    }
  }, [favicon]);

  // Get DNI images from Firestore or create a new document
  useEffect(() => {
    const unsub = onSnapshot(dniRef, (document) => {
      if (document.exists()) {
        const data = document.data() as FirebaseDniData;

        const dniImages: DniImageType[] = ["frontDNI", "backDNI"];

        dniImages.forEach((dniImage) => {
          const imageDNI = data[dniImage];

          setDniData((prevState) => ({
            ...prevState,
            [dniImage]: imageDNI,
            scannedQR: data.scannedQR,
          }));
          setIsLoadingData(false);
        });
      } else {
        setDoc(dniRef, dniData, { merge: true });
      }
    });

    return unsub;
  }, []);

  // Set QR information
  useEffect(() => {
    QRCode.toDataURL(dniLink.concat("&withQR=true"), qrStyle).then((img) =>
      dispatch(onUpdateQrImage(img))
    );

    dispatch(
      onUpdateSuccessMessage("Ya podés cargar las fotos desde tu teléfono.")
    );

    dispatch(
      onUpdateOnClose(() => {
        setDoc(dniRef, { scannedQR: false }, { merge: true });
      })
    );
  }, []);

  //Update QR modal
  useEffect(() => {
    if (continueWithQR && !isLoadingData) {
      logAnalyticsAndHotjarEvent("qr_code_scanned_dni");
      setDoc(dniRef, { scannedQR: true }, { merge: true });
      localStorage.clear();
    }
  }, [continueWithQR, isLoadingData]);

  useEffect(() => {
    dniData.scannedQR && dispatch(onUpdateScannedQr(true));
  }, [dniData]);

  // Close modal when all files were uploaded
  useEffect(() => {
    if (!disablePrimaryButton) {
      dispatch(closeModal());
    }
  }, [dniData, error]);

  // Update token if it comes from URL
  useEffect(() => {
    if (urlToken) {
      dispatch(updateToken(urlToken));
    }
  }, [urlToken]);

  // Manage buttons
  useEffect(() => {
    if (!isMobile) {
      dispatch(
        onUpdateSecondaryButton({
          text: "Cargar con mi teléfono",
          disabled: false,
          isLoading: false,
          onClick: handleOpenQr,
          show: true,
        })
      );

      dispatch(
        onUpdateTertiaryButton({
          text: "Volver",
          disabled: false,
          isLoading: false,
          onClick: handleBack,
          show: true,
        })
      );
    } else {
      dispatch(
        onUpdateSecondaryButton({
          text: "Volver",
          disabled: false,
          isLoading: false,
          onClick: handleBack,
          show: true,
        })
      );

      dispatch(
        onUpdateTertiaryButton({
          show: false,
        })
      );
    }

    dispatch(
      onUpdatePrimaryButton({
        text: "Continuar",
        disabled: disablePrimaryButton,
        isLoading: isLoadingButton,
        onClick: handleContinue,
        show: true,
      })
    );
  }, [dniData, isLoadingButton, isMobile]);

  return (
    <section>
      {!continueWithQR && <QRModal />}

      {success ? (
        <div className={successContainer}>
          <img src={successIcon} alt="successQRIcon" />
          <h2>¡Listo! Podés cerrar esta página y seguir en tu computadora.</h2>
        </div>
      ) : (
        <div>
          {isLoadingData ? (
            <div className={loading}>
              <LoadingCircle width="50" />
            </div>
          ) : isExternalLink ? (
            <Layout
              hideStepper
              hideLogo={continueWithQR}
              hideSupportButton={continueWithQR}
            >
              <ToastMessage messageId="formAlert" position="top-center" />
              <Container className={continueWithQR ? withQr : ""}>
                <FormContainer
                  icon={icon}
                  currentStep={!continueWithQR ? dniStep : undefined}
                  currentSubStep={1}
                  className={isExternalLink ? externalLink : ""}
                  hideHeader={continueWithQR}
                >
                  <UploadDniForm
                    uuid={uuid}
                    onboardingType={onboardingType as OnboardingType}
                    dniData={dniData}
                    dniRef={dniRef}
                    setError={setError}
                    setIsLoadingButton={setIsLoadingButton}
                    isExternalLink={isExternalLink}
                    continueWithQR={continueWithQR}
                    isMobile={isMobile}
                    uploadedSuccessfully={uploadedSuccessfully}
                    cuil={cuit}
                  />
                </FormContainer>
              </Container>
              {!uploadedSuccessfully && (
                <div className="flex gap-2 w-full justify-center">
                  {isExternalLink && !continueWithQR && (
                    <Button
                      variant="secondary"
                      text="Cargar con mi teléfono"
                      className={secondaryButton}
                      onClick={handleOpenQr}
                    />
                  )}
                  {isExternalLink && (
                    <Button
                      text={continueWithQR ? "Confirmar" : "Continuar"}
                      className={continueWithQR ? confirmButton : ""}
                      fullWidth={continueWithQR}
                      size={isMobile ? "mobile" : "desktop"}
                      disabled={disabledExternalLinkButton}
                      isLoading={isLoadingButton}
                      onClick={handleUpload}
                    />
                  )}
                </div>
              )}
            </Layout>
          ) : (
            <>
              <UploadDniForm
                uuid={uuid}
                onboardingType={onboardingType as OnboardingType}
                dniData={dniData}
                dniRef={dniRef}
                setError={setError}
                setIsLoadingButton={setIsLoadingButton}
                isExternalLink={isExternalLink}
                continueWithQR={continueWithQR}
                isMobile={isMobile}
                cuil={cuit}
              />
              {isJuridicalOnboarding && (
                <div className={copyLinkContainer}>
                  <h4>
                    O compartí el siguiente link y esperá a que carguen las
                    fotos.
                  </h4>
                  <InputCopy value={dniLink} onClick={handleCopyLink} />
                </div>
              )}
            </>
          )}
        </div>
      )}
    </section>
  );
};

export default UploadDniPage;
