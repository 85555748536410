import { useEffect, useState } from "react";

// TYPES
import {
  ComponentProps,
  JuridicalShareholder,
  PhysicalShareholderFormik,
  ShareholderData,
  StepEndpoint,
  StepFourPJ,
} from "../../types";

// UTILS
import {
  genericToastifyError,
  getCompanyData,
  scrollToElement,
} from "../../utils";
import {
  onUpdatePrimaryButton,
  onUpdateSecondaryButton,
} from "../../reducers/buttonsReducer";
import { onUpdateActiveIndex } from "../../reducers/collectionReducer";
import { emptyPhysicalShareholder } from "../../templates/allaria/juridical";
import { sendStep } from "../../connectors/connectors";

// HOOKS
import { useAppDispatch, useAppSelector } from "../../app/hooks";
import useWindowWidth from "../../hooks/useWindowWidth";
import useForm from "../../hooks/useForm";

// COMPONENTS
import { Button, showToastMessage } from "@almafintech/react-components";
import Shareholder from "./Shareholder";

// ICONS
import AddCircle from "../../assets/images/icons/ui/add-circle-outline.svg?react";

import styles from "./Shareholders.module.scss";
import AlertModal from "../AlertModal/AlertModal";
import {
  addSendedStep,
  onUpdateCompletedStep,
  onUpdateFormData,
  onUpdateStep,
} from "../../reducers/onboardingDataReducer";
import {
  onUpdatePrimaryButtonModal,
  openModal,
} from "../../reducers/modalReducer";

const Shareholders = ({ formik }: ComponentProps) => {
  const {
    shareholdersContainer,
    shareholdersList,
    addShareholderButton,
    addShareholderContainer,
    disabledContainer,
  } = styles;

  const [buttonIsLoading, setButtonIsLoading] = useState(false);

  const dispatch = useAppDispatch();

  const { handleBack } = useForm(formik);

  const { formValues, sendedSteps } = useAppSelector(
    (state) => state.onboardingData
  );

  const { isEditing, activeIndex } = useAppSelector(
    (state) => state.collectionData
  );

  const shareholders: ShareholderData[] = formValues?.shareholders || [];

  const dimensions = useWindowWidth();
  const isMobile = dimensions.width < 768;

  const totalOwnership = shareholders?.reduce(
    (acc: number, shareholder: ShareholderData) =>
      acc + (Number(shareholder?.ownership) || 0),
    0
  );
  const hitOwnershipLimit = totalOwnership == 100;

  const isUncompletedShareholder = (shareholder: ShareholderData) => {
    return (
      !shareholder ||
      Object.entries(shareholder).some(([key, value]) => {
        return (
          !value &&
          key !== "spouseName" &&
          key !== "spouseLastName" &&
          key !== "spouseCuil" &&
          key !== "pepMotive"
        );
      })
    );
  };

  const addNewShareholder = () => {
    const newShareholders = [...shareholders, emptyPhysicalShareholder];
    dispatch(onUpdateFormData({ shareholders: newShareholders }));
  };

  const handleAddShareholder = () => {
    if (!shareholders) return;
    addNewShareholder();
    scrollToElement(`shareholder-${shareholders.length + 1}`);
    dispatch(onUpdateActiveIndex(shareholders?.length + 1));
  };

  const isDisableOnContinue =
    !shareholders ||
    shareholders.length === 0 ||
    shareholders.some(isUncompletedShareholder);

  const isDisabledAddShareholder =
    !shareholders ||
    hitOwnershipLimit ||
    shareholders.some(isUncompletedShareholder);

  const onClickPrimaryButton = async () => {
    try {
      setButtonIsLoading(true);
      const shareholders = formValues?.shareholders;

      //Remove from sendedSteps the step with the same type. Prevents duplicates
      const filteredSendedSteps = sendedSteps.filter(
        (step: StepEndpoint) => step["@type"] !== "4_JURIDICAL"
      );

      const parseShareholder = (shareholder: ShareholderData) => {
        if (!shareholder) return undefined;
        if (shareholder.personType === "JURIDICAL") {
          return parseJuridical(shareholder as JuridicalShareholder);
        } else return parsePhysical(shareholder as PhysicalShareholderFormik);
      };

      const parseJuridical = (shareholder: JuridicalShareholder) => {
        const { foreignTaxDetailBool, foreignTaxDetail, ...restShareholder } =
          shareholder;

        return {
          ...restShareholder,
          "@type": "JURIDICAL",
          foreignTaxDetail:
            foreignTaxDetailBool === "NO" ? null : foreignTaxDetail,
        };
      };

      const parsePhysical = (shareholder: PhysicalShareholderFormik) => {
        const {
          spouseName,
          spouseLastName,
          spouseCuil,
          foreignTaxDetailBool,
          foreignTaxDetail,
          pepMotive,
          pep,
          birthDate,
          ...restShareholder
        } = shareholder;

        const {
          fullAddress,
          stateSelect,
          stateText,
          ...addressWithoutFullAddress
        } = restShareholder.address;

        //MARK AS USED
        void fullAddress;
        void stateSelect;
        void stateText;

        const parsePep = (pep: string | undefined) => {
          if (pep === null || pep === undefined) {
            return "NONE";
          } else return pep;
        };

        const parsedPep = parsePep(pep);

        return {
          "@type": "PHYSICAL",
          ...restShareholder,
          pep: parsedPep,
          ...(parsedPep !== "NONE" && { pepDetails: pepMotive }),
          foreignTaxDetail:
            foreignTaxDetailBool === "NO" ? null : foreignTaxDetail,
          birthDate: birthDate,
          address: addressWithoutFullAddress,
          ...(spouseCuil &&
            spouseName &&
            spouseLastName && {
              spouse: {
                name: spouseName,
                lastName: spouseLastName,
                cuit: spouseCuil,
              },
            }),
        };
      };

      const shareholdersPhysicalsFiltered =
        shareholders
          ?.filter((share: ShareholderData) => share && share.taxId)
          .map(parseShareholder) ?? [];
      const step: StepFourPJ = {
        "@type": "4_JURIDICAL",
        shareholders: shareholdersPhysicalsFiltered ?? [],
      };

      await sendStep({
        stepId: 4,
        body: {
          companyType: getCompanyData().companyName,
          personType: "JURIDICAL", //Always will be JURIDICAL
          email: formValues?.email || "",
          steps: [...filteredSendedSteps, step],
        },
      });

      dispatch(addSendedStep(step));
      dispatch(onUpdateCompletedStep(6));
      dispatch(onUpdateStep(7));
    } catch (error) {
      showToastMessage(genericToastifyError, {
        containerId: "layoutPage",
        type: "error",
      });
      console.log(error);
      throw new Error("Error at shareholders");
    } finally {
      setButtonIsLoading(false);
    }
  };

  useEffect(() => {
    if (!shareholders || shareholders.length === 0) {
      addNewShareholder();
    }
  }, [shareholders]);

  useEffect(() => {
    dispatch(onUpdateActiveIndex(1));
  }, []);

  useEffect(() => {
    dispatch(
      onUpdateSecondaryButton({
        text: "Volver",
        disabled: false,
        isLoading: false,
        onClick: handleBack,
        show: true,
      })
    );
  }, []);

  // Handle primary button
  useEffect(() => {
    dispatch(
      onUpdatePrimaryButton({
        text: "Continuar",
        disabled: isDisableOnContinue,
        isLoading: buttonIsLoading,
        onClick: onClickPrimaryButton,
        show: true,
      })
    );
  }, [shareholders]);

  return (
    <section className={shareholdersContainer}>
      <AlertModal
        id="discard-new-changes-shareholder"
        title="¿Descartar accionista?"
        message="Se descartarán los datos ingresados que no fueron guardados."
      />
      <div className={shareholdersList}>
        {shareholders?.map((shareholder, i) => (
          <Shareholder
            key={`shareholder-${i + 1}`}
            shareholder={shareholder}
            index={i + 1}
            showDeletePreconfirmation={!isUncompletedShareholder(shareholder)}
            onClick={
              isEditing && activeIndex !== i + 1
                ? () => {
                    dispatch(
                      openModal({ name: "discard-new-changes-shareholder" })
                    );
                    dispatch(
                      onUpdatePrimaryButtonModal({
                        text: "Sí, eliminar",
                        action: () => dispatch(onUpdateActiveIndex(i + 1)),
                      })
                    );
                  }
                : () => dispatch(onUpdateActiveIndex(i + 1))
            }
          />
        ))}
        {isMobile && (
          <div
            className={`${addShareholderContainer} ${
              isDisabledAddShareholder ? disabledContainer : ""
            }`}
            onClick={
              isDisabledAddShareholder ? () => null : handleAddShareholder
            }
          >
            <AddCircle />
          </div>
        )}
      </div>
      {!isMobile && (
        <Button
          className={addShareholderButton}
          text="Agregar accionista"
          onClick={handleAddShareholder}
          disabled={isDisabledAddShareholder}
        />
      )}
    </section>
  );
};

export default Shareholders;
