import { useEffect, useState } from "react";

// DEPENDENCIES
import * as Yup from "yup";
import * as Sentry from "@sentry/react";
import { saveAs } from "file-saver";
import { useFormik } from "formik";
import { useAppDispatch, useAppSelector } from "../../app/hooks";
import { useNavigate, useSearchParams } from "react-router-dom";

// UTILS
import {
  genericToastifyError,
  hasJointSignature,
  isToros,
  isWina,
} from "../../utils";
import {
  onUpdateCompletedForm,
  onUpdateStep,
} from "../../reducers/onboardingDataReducer";
import { onUpdatePreconfirmation } from "../../reducers/preconfirmationReducer";
import useWindowWidth from "../../hooks/useWindowWidth";
import juridicalTemplate from "../../templates/allaria/juridical";
import physicalTemplate from "../../templates/allaria/physical";
import signatoryTemplate from "../../templates/allaria/signatory";
import { logAnalyticsAndHotjarEvent } from "../../main";
import { onUpdateActiveTemplate } from "../../reducers/activeTemplateReducer";

// TYPES
import { Preconfirmation } from "../../types";

// CONNECTORS
import { completeDraft } from "../../connectors/connectors";

// COMPONENTS
import { showToastMessage } from "@almafintech/react-components/ToastMessage";
import { Checkbox } from "@almafintech/react-components/Checkbox";
import { Button } from "@almafintech/react-components/Button";
import PreconfirmationCard from "../PreconfirmationCard/PreconfirmationCard";
import Layout from "../Layout/Layout";

// ICONS
import padlock from "../../assets/images/icons/ui/padlock-grey.svg";

// STYLES
import styles from "./PreconfirmationPage.module.scss";

const PreconfirmationPage = () => {
  const {
    container,
    center,
    checkboxesGroup,
    checkboxWrapper,
    checkboxLabel,
    optionalText,
    optionalBox,
    buttonsGroup,
    button,
    text,
  } = styles;

  const state = useAppSelector((state) => state);
  const { draftUUID, formValues } = state.onboardingData;
  const { onboardingType, steps } = state.activeTemplate;

  const successSearchParams = new URLSearchParams();
  if (isToros(formValues)) successSearchParams.append("toros", "true");
  if (isWina(formValues)) successSearchParams.append("wina", "true");
  if (hasJointSignature(formValues))
    successSearchParams.append("signatories", "true");

  const successPage = `/apertura-exitosa${
    successSearchParams.toString() ? `?${successSearchParams.toString()}` : ""
  }`;

  const [searchParams] = useSearchParams();
  const urlTemplate = searchParams.get("template");

  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  const [preconfirmationData, setPreconfirmationData] =
    useState<Preconfirmation | null>(null);

  const previousStep = steps && steps?.length - 1;

  const handleBack = () => {
    const obType = () => {
      if (onboardingType === "PHYSICAL") return "/personal";
      else if (onboardingType === "JURIDICAL") return "/empresarial";
      else if (onboardingType === "SIGNATORY") return `/firmante?externalCode=${draftUUID}&email=${formValues?.email}`;
      else return "/";
    };
    dispatch(onUpdatePreconfirmation(true));
    navigate(obType());
    dispatch(onUpdateStep(Number(previousStep)));
  };

  const fieldsInitialValues =
    preconfirmationData?.checkboxes?.reduce(
      (acc: Record<string, boolean>, { name, initialValue }) => {
        acc[name] = initialValue;
        return acc;
      },
      {}
    ) || {};

  const fieldsValidations = preconfirmationData?.checkboxes?.reduce(
    (acc: Record<string, Yup.Schema>, { name, validation }) => {
      acc[name] = validation;
      return acc;
    },
    {}
  );

  const formik = useFormik({
    initialValues: fieldsInitialValues,
    validationSchema: Yup.object().shape({ ...fieldsValidations }),
    onSubmit: async () => {
      try {
        if (preconfirmationData?.sendTermsAndConditions)
          await preconfirmationData.sendTermsAndConditions(state, values);

        await completeDraft({ draftUUID });

        dispatch(onUpdateCompletedForm(true));
        dispatch({ type: "reset-store" });
        resetForm();
        logAnalyticsAndHotjarEvent("onboarding_completed", {
          personType: onboardingType,
        });
        navigate(successPage);
      } catch (error) {
        console.log(error);
        Sentry.captureException(error);
        showToastMessage(genericToastifyError, {
          containerId: "layoutPage",
          type: "error",
        });
      }
    },
  });

  const {
    getFieldProps,
    values,
    handleSubmit,
    isSubmitting,
    setValues,
    resetForm,
  } = formik;

  const isDisabled =
    !preconfirmationData?.checkboxes &&
    !preconfirmationData?.sendTermsAndConditions
      ? false
      : !values.termsAndConditions || isSubmitting;

  const linksData = preconfirmationData?.checkboxes?.flatMap(
    ({ links }) => links || []
  );
  const linksElements = linksData?.map(({ id }) => document.getElementById(id));

  const handleOnClick = (
    e: React.MouseEvent<HTMLInputElement>,
    name: string
  ) => {
    e.preventDefault();

    const target = e.target as Node;
    const label = document.getElementById(`label-${name}`);

    // Find if user clicked on a link to terms or notes
    const linkClicked = linksElements?.find((link) => link?.contains(target));

    if (linkClicked) {
      // Find the link data to download the file
      const linkData = linksData?.find(({ id }) => id === linkClicked.id);
      saveAs(linkData?.url || "", `${linkData?.name}.pdf`);
    } else if (!label?.contains(target)) {
      toggleCheckbox(name);
    }
  };

  const toggleCheckbox = (name: string) => {
    setValues((prevValues) => ({
      ...prevValues,
      [name]: !prevValues[name],
    }));
  };

  const dimensions = useWindowWidth();
  const isMobile = dimensions.width < 768;

  const loadTemplate = async () => {
    if (urlTemplate === "PHYSICAL") {
      const template = await physicalTemplate();
      dispatch(onUpdateActiveTemplate(template));
    }

    if (urlTemplate === "JURIDICAL") {
      const template = await juridicalTemplate();
      dispatch(onUpdateActiveTemplate(template));
    }

    if (urlTemplate === "SIGNATORY") {
      const template = await signatoryTemplate();
      dispatch(onUpdateActiveTemplate(template));
    }
  };

  useEffect(() => {
    if (!onboardingType) loadTemplate();
  }, []);

  useEffect(() => {
    const { preconfirmation } = state.activeTemplate;

    if (preconfirmation) {
      const preconfirmationData = preconfirmation({
        values: formValues,
      });

      setPreconfirmationData(preconfirmationData);
    }
  }, [onboardingType]);

  useEffect(() => {
    if (values.termsAndConditions) {
      logAnalyticsAndHotjarEvent("terms_accepted");
    }
  }, [values]);

  return (
    <Layout hideLogo={!isMobile} hideStepper className={container}>
      <PreconfirmationCard
        preconfirmationSteps={preconfirmationData?.steps || []}
      />
      <form className={checkboxesGroup}>
        {preconfirmationData?.checkboxes?.map(({ name, label, required }) => (
          <div className="flex gap-2" key={name}>
            <Checkbox
              {...getFieldProps(name)}
              isSelected={values[name]}
              classNames={{
                wrapper: checkboxWrapper,
              }}
              onClick={(e) => handleOnClick(e, name)}
            >
              <div className={checkboxLabel} id={`label-${name}`}>
                {label}
                {!required && isMobile && (
                  <p className={optionalText}>(opcional)</p>
                )}
              </div>
            </Checkbox>
            {!required && !isMobile && <p className={optionalBox}>Opcional</p>}
          </div>
        ))}
      </form>

      <div className={center}>
        <div className={buttonsGroup}>
          <Button
            text="Volver"
            variant="secondary"
            type="button"
            onClick={handleBack}
            className={button}
          />
          <Button
            text="Abrir cuenta"
            variant="primary"
            type="submit"
            onClick={() => handleSubmit()}
            isLoading={isSubmitting}
            disabled={isDisabled}
          />
        </div>
        {!isMobile && (
          <div className={text}>
            <img src={padlock} alt="candado" />
            <span>
              Todos tus datos son guardados con seguridad y no serán compartidos
            </span>
          </div>
        )}
      </div>
    </Layout>
  );
};

export default PreconfirmationPage;
