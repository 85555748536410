import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { FormikValues } from "formik";
import { DraftUUID, StepEndpoint } from "../types";

interface InitialProps {
  draftUUID: DraftUUID;
  stepData: {
    step: number | string | null;
    subStep: number | null;
    disabledSubStep: number | null;
    completedSteps: number[] | null;
    reachedSteps: number[] | null;
    lastCompletedSubStep: number;
    numericStepList: number[] | null;
  };
  completedForm: boolean;
  formValues: FormikValues | null;
  sendedSteps: StepEndpoint[];
  isExternalInformation: boolean; //ESCO
}

const initialState: InitialProps = {
  draftUUID: "",
  stepData: {
    step: null,
    subStep: null,
    disabledSubStep: null,
    completedSteps: null,
    reachedSteps: null,
    lastCompletedSubStep: 0,
    numericStepList: null,
  },
  completedForm: false,
  formValues: null,
  sendedSteps: [],
  isExternalInformation: false,
};

const onboardingDataSlice = createSlice({
  name: "onboardingData",
  initialState,
  reducers: {
    onUpdateDraftUUID: (state, action: PayloadAction<DraftUUID>) => {
      return { ...state, draftUUID: action.payload };
    },
    onUpdateStep: (state, action: PayloadAction<number | string | null>) => {
      return {
        ...state,
        stepData: { ...state.stepData, step: action.payload },
      };
    },
    onUpdateSubStep: (state, action: PayloadAction<number | null>) => {
      return {
        ...state,
        stepData: { ...state.stepData, subStep: action.payload },
      };
    },
    onUpdateDisabledSubStep: (state, action: PayloadAction<number | null>) => {
      return {
        ...state,
        stepData: { ...state.stepData, disabledSubStep: action.payload },
      };
    },
    onUpdateCompletedStep: (state, action: PayloadAction<number>) => {
      // Create an array with all the steps from 1 to the new completed step
      const newCompletedSteps = Array.from(
        { length: action.payload },
        (_, i) => i + 1
      );

      return {
        ...state,
        stepData: {
          ...state.stepData,
          completedSteps: newCompletedSteps,
        },
      };
    },
    onUpdateReachedStep: (state, action: PayloadAction<number>) => {
      // Create an array with all the steps from 1 to the new reached step
      const newReachedSteps = Array.from(
        { length: action.payload },
        (_, i) => i + 1
      );

      return {
        ...state,
        stepData: {
          ...state.stepData,
          reachedSteps: newReachedSteps,
        },
      };
    },
    onUpdateLastCompletedSubStep: (state, action: PayloadAction<number>) => {
      return {
        ...state,
        stepData: {
          ...state.stepData,
          lastCompletedSubStep: action.payload,
        },
      };
    },
    onUpdateCompletedForm: (state, action: PayloadAction<boolean>) => {
      return { ...state, completedForm: action.payload };
    },
    onUpdateLoading: (state, action: PayloadAction<boolean>) => {
      return { ...state, isLoading: action.payload };
    },
    onUpdateFormData: (state, action: PayloadAction<FormikValues>) => {
      return {
        ...state,
        formValues: {
          ...state.formValues,
          ...action.payload,
        },
      };
    },
    addSendedStep: (state, action: PayloadAction<StepEndpoint>) => {
      if (!state.sendedSteps)
        return { ...state, sendedSteps: [action.payload] };
      else {
        const filteredState = state.sendedSteps.filter(
          (step) => step["@type"] !== action.payload["@type"]
        );

        filteredState.push(action.payload);
        return {
          ...state,
          sendedSteps: filteredState,
        };
      }
    },
    onUpdateHasExternalInformation: (state, action: PayloadAction<boolean>) => {
      return { ...state, isExternalInformation: action.payload };
    },
    onResetForm: () => {
      return initialState;
    },
  },
});

export const {
  onUpdateDraftUUID,
  onUpdateStep,
  onUpdateSubStep,
  onUpdateDisabledSubStep,
  onUpdateCompletedForm,
  onUpdateFormData,
  onResetForm,
  onUpdateCompletedStep,
  onUpdateReachedStep,
  onUpdateLastCompletedSubStep,
  onUpdateHasExternalInformation,
  addSendedStep,
} = onboardingDataSlice.actions;

export default onboardingDataSlice.reducer;
