import { useEffect, useMemo, useState } from "react";

// DEPENDENCIES
import { useFormik } from "formik";

// UTILS
import {
  generateYupSchema,
  getIsConditionallyTrue,
  getKeys,
  isEmptyObject,
  isSchemaRequired,
} from "./utils";
import { onUpdatePrimaryButtonModal } from "../../reducers/modalReducer";
import { onUpdateIsEditing } from "../../reducers/collectionReducer";

// HOOKS
import useWindowWidth from "../../hooks/useWindowWidth";
import { useAppDispatch, useAppSelector } from "../../app/hooks";

// TYPES
import { SecondaryForm } from "../../types";

// COMPONENTS
import { Button } from "@almafintech/react-components/Button";
import Container from "../Container/Container";
import TemplateInputSelector from "./TemplateInputSelector";
import Tabs from "../Tabs/Tabs";
import AlertModal from "../AlertModal/AlertModal";

import styles from "../TemplateInterpreter/TemplateInterpreter.module.scss";

const TemplateInterpreterSecondaryForm = ({
  collectionName,
  tabs,
  fields,
  onSave,
  alert,
}: SecondaryForm) => {
  const { formContainer, maxHeight, secondaryForm, saveButton } = styles;

  const dispatch = useAppDispatch();

  const state = useAppSelector((state) => state);
  const { formValues } = state.onboardingData;
  const { activeIndex } = state.collectionData;

  const collection = useMemo(
    () => formValues?.[collectionName] || {},
    [formValues, collectionName]
  );

  const activeData = useMemo(
    () => collection[activeIndex - 1] || {},
    [collection, activeIndex]
  );

  const [activeTab, setActiveTab] = useState<string>(
    tabs?.active(activeData) || ""
  );

  const initialsecondaryFormValues = fields.reduce(
    (acc: Record<string, string>, { name, initialValue }) => {
      acc[name] = initialValue;
      return acc;
    },
    {}
  );

  const secondaryFormFieldsValidations = generateYupSchema(fields);

  const secondaryFormFormik = useFormik({
    initialValues: initialsecondaryFormValues,
    validationSchema: secondaryFormFieldsValidations,
    onSubmit: (_, { setSubmitting }) => {
      setSubmitting(false);
    },
  });

  const {
    values,
    setValues,
    resetForm,
    setTouched,
    errors,
    setErrors,
    isSubmitting,
    setSubmitting,
  } = secondaryFormFormik;

  const requiredFieldsNames = useMemo(() => {
    return fields
      .filter(({ validation, conditional, allConditionalsTrue }) => {
        const requiredSchema = isSchemaRequired(validation);
        return (
          requiredSchema &&
          (!conditional ||
            getIsConditionallyTrue(values, conditional, allConditionalsTrue))
        );
      })
      .map(({ name }) => name);
  }, [fields, values]);

  const requiredFieldsHaveErrors =
    getKeys(errors).filter((key) => requiredFieldsNames?.includes(key)).length >
    0;

  const hasUnsavedChanges = useMemo(() => {
    return (
      values &&
      activeData &&
      Object.entries(values).some(([key, value]) => {
        activeData[key] !== value;
      })
    );
  }, [values, activeData]);

  const reset = () => {
    resetForm();
    setTouched({}, false);
    setErrors({});
  };

  const handleActiveTabChange = (tab: string) =>
    tabs?.onChange(tab, setActiveTab, dispatch, state, values);

  const isSaveButtonDisabled =
    requiredFieldsHaveErrors || hasUnsavedChanges || isSubmitting;

  const dimensions = useWindowWidth();
  const isMobile = dimensions.width < 768;

  const handleSubmit = () => {
    onSave(values, dispatch, state);
    setSubmitting(false);
  };

  useEffect(() => {
    if (!isEmptyObject(activeData)) {
      setValues(activeData, false);
      setTouched({}, false);
      setErrors({});
    }
  }, [activeData]);

  useEffect(() => {
    if (isEmptyObject(values)) reset();
  }, [values]);

  useEffect(() => {
    if (tabs) setActiveTab(tabs.active(activeData));
  }, [activeData]);

  useEffect(() => {
    if (alert)
      dispatch(
        onUpdatePrimaryButtonModal({
          text: alert.primaryButton.text,
          action: () => alert.primaryButton.action(activeTab, dispatch, state),
        })
      );
  }, [activeData, activeTab]);

  useEffect(() => {
    dispatch(onUpdateIsEditing(hasUnsavedChanges));
  }, [hasUnsavedChanges]);

  return (
    <Container className={`flex-col ${secondaryForm}`}>
      {tabs && (
        <Tabs
          options={tabs.options}
          active={activeTab}
          onChange={handleActiveTabChange}
          disabled={tabs.disabled ? tabs.disabled(state) : false}
        />
      )}
      {alert && <AlertModal {...alert} />}
      <form className={`${formContainer}  ${tabs ? maxHeight : ""}`}>
        {fields.map((field) => (
          <TemplateInputSelector
            currentStepFields={fields}
            key={field.name}
            field={field}
            formik={secondaryFormFormik}
          />
        ))}
      </form>
      <Button
        text="Guardar"
        type="submit"
        onClick={handleSubmit}
        isLoading={isSubmitting}
        isDisabled={isSaveButtonDisabled}
        fullWidth={isMobile}
        variant={isMobile ? "secondary" : "primary"}
        className={saveButton}
      />
    </Container>
  );
};

export default TemplateInterpreterSecondaryForm;
