import { useSearchParams } from "react-router-dom";
import { getCompanyData } from "../../utils";
import { Alert } from "@almafintech/react-components/Alert";
import Container from "../Container/Container";
import Layout from "../Layout/Layout";

import successIcon from "../../assets/images/icons/ui/ui-icon-success.svg";
import torosCapitalLogo from "../../assets/images/logos/toros-capital.png";
import winaLogo from "../../assets/images/logos/wina.svg";

import styles from "./SuccessPage.module.scss";

const SuccessPage = () => {
  const { successContainer, body, logosContainer, torosLogo, companyLogo } =
    styles;

  const [searchParams] = useSearchParams();
  const isToros = searchParams.get("toros");
  const isWina = searchParams.get("wina");
  const signatories = searchParams.get("signatories");

  const successSubtitle = signatories
    ? "Procesaremos tu solicitud y enviaremos un mail a cada firmante con las instrucciones para su registro."
    : "Procesaremos tu solicitud y recibirás un mail a la brevedad con las   instrucciones para acceder a tu cuenta.";

  const { logo, logoClassname, companyName } = getCompanyData();

  return (
    <Layout hideLogo hideSupportButton>
      <Container className={successContainer}>
        <div className={logosContainer}>
          {isToros && (
            <img
              className={torosLogo}
              src={torosCapitalLogo}
              alt="Toros Capital logo"
              width={160}
            />
          )}
          {isWina && (
            <img
              className={companyLogo}
              src={winaLogo}
              alt="Wina logo"
              width={160}
            />
          )}
          <img
            className={`${companyLogo} ${
              logoClassname ? styles[logoClassname] : ""
            }`}
            src={logo}
            alt={`${companyName} logo`}
            width={160}
          />
        </div>
        <div className={body}>
          <img src={successIcon} alt="success" />
          <h1>¡Terminaste el registro!</h1>
          <p>{successSubtitle}</p>
          {signatories && (
            <Alert variant="INFO">
              Cuando los firmantes se registren, todos recibirán las
              instrucciones para acceder a la cuenta.
            </Alert>
          )}
        </div>
      </Container>
    </Layout>
  );
};

export default SuccessPage;
