import { useState, useEffect, useMemo } from "react";
import { FormikValues, useFormik } from "formik";
import { Button } from "@almafintech/react-components/Button";
import "../../App.css";
import { useAppDispatch, useAppSelector } from "../../app/hooks";
import {
  onUpdateStep,
  onUpdateSubStep,
  onUpdateFormData,
} from "../../reducers/onboardingDataReducer";
import { Field, Step } from "../../types";
import FormContainer from "../FormContainer/FormContainer";
import Container from "../Container/Container";
import Layout from "../Layout/Layout";
import { useNavigate, useSearchParams } from "react-router-dom";
import {
  onUpdateActiveTemplate,
  onUpdateOnboardingType,
} from "../../reducers/activeTemplateReducer";
import physicalTemplate from "../../templates/allaria/physical";
import juridicalTemplate from "../../templates/allaria/juridical";
import TemplateInterpreterForm from "./TemplateInterpreterForm";
import TemplateInterpreterSecondaryForm from "./TemplateInterpreterSecondaryForm";
import {
  onUpdatePrimaryButton,
  onUpdateSecondaryButton,
  onUpdateTertiaryButton,
} from "../../reducers/buttonsReducer";
import { isBasicStep, isComponentStep, MAIL, WHATSAPP_LINK } from "../../utils";
import { LoadingCircle } from "@almafintech/react-components/LoadingCircle";
import PEPModal from "../PEPModal/PEPModal";
import TramitNumberModal from "../TramitNumberModal/TramitNumberModal";
import RiskProfileModal from "../RiskProfileModal/RiskProfileModal";
import { onUpdateInfoStep } from "../../reducers/infoStepReducer";
import { getDraft } from "../../connectors/connectors";
import {
  generateYupSchema,
  getIsConditionallyTrue,
  getKeys,
  getValue,
  isEmptyObject,
  isSchemaRequired,
  reorderFields,
  setNestedValue,
} from "./utils";
import useForm from "../../hooks/useForm";
import { logAnalyticsAndHotjarEvent } from "../../main";
import EditDNIModal from "../EditDNIModal/EditDNIModal";

import styles from "./TemplateInterpreter.module.scss";
import onboardingSignatory from "../../templates/allaria/signatory";

const TemplateInterpreter = () => {
  const {
    loading: loadingStyle,
    buttonsGroup,
    button,
    component,
    secondaryFormTemplate,
    templateContainer,
    helpTextContainer,
    form,
  } = styles;

  const [searchParams] = useSearchParams();
  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  const [loading, setLoading] = useState({
    template: true,
    formValues: true,
  });

  const state = useAppSelector((state) => state);

  const {
    activeTemplate: template,
    buttons: { primaryButtonData, secondaryButtonData, tertiaryButtonData },
    onboardingData: {
      stepData: { step, subStep, reachedSteps },
      formValues,
    },
    preconfirmation: { isEditingForm },
  } = state;

  // Email or name of the person that referred the user
  const referrer = searchParams.get("ref");

  // Redirect to onboarding and set steps
  useEffect(() => {
    if (template.onboardingType) return;

    if (window.location.pathname === "/personal") {
      dispatch(onUpdateOnboardingType("PHYSICAL"));
      dispatch(onUpdateStep(step || 1));
      dispatch(onUpdateSubStep(subStep || 1));
    }

    if (window.location.pathname === "/empresarial") {
      dispatch(onUpdateOnboardingType("JURIDICAL"));
      dispatch(onUpdateStep(1));
      dispatch(onUpdateSubStep(1));
    }

    if (window.location.pathname === "/firmante") {
      dispatch(onUpdateOnboardingType("SIGNATORY"));
      dispatch(onUpdateStep(1));
      dispatch(onUpdateSubStep(1));
    }
  }, [template]);

  // Load template based on onboarding type
  const loadTemplate = async () => {
    if (!template?.onboardingType) {
      navigate("/");
      return;
    }

    if (template?.onboardingType === "PHYSICAL") {
      const template = await physicalTemplate();
      dispatch(onUpdateActiveTemplate(template));
    }

    if (template?.onboardingType === "JURIDICAL") {
      const template = await juridicalTemplate();
      dispatch(onUpdateActiveTemplate(template));
    }

    if (template?.onboardingType === "SIGNATORY") {
      const template = await onboardingSignatory();
      dispatch(onUpdateActiveTemplate(template));
    }
  };

  // Load template
  // Set favicon
  useEffect(() => {
    if (!template?.onboardingType) return;

    if (!template.formTitle) {
      loadTemplate();
    } else {
      setLoading((prevState) => ({
        ...prevState,
        template: false,
      }));
    }

    if (template?.favicon) {
      const newLink = document.createElement("link");
      newLink.rel = "icon";
      newLink.type = "image/png";
      newLink.href = template.favicon;
      document.head.appendChild(newLink);
    }
  }, [template]);

  // Flatten step fields into a single array
  const flattenStepFields = useMemo(
    () =>
      template?.steps?.reduce(
        (acc: Field[], { fields, subSteps, secondaryForm }) => {
          if (subSteps) {
            subSteps.forEach(({ fields, secondaryForm }: Step) => {
              if (fields && !secondaryForm) acc.push(...fields);
            });
          } else {
            if (fields && !secondaryForm) acc.push(...fields);
          }
          return acc;
        },
        []
      ),
    [template?.steps]
  );

  // Flatten step fields initial values
  const flattenStepFieldsInitialValues = useMemo(
    () =>
      flattenStepFields?.reduce((acc: FormikValues, { name, initialValue }) => {
        setNestedValue(acc, name, initialValue);
        return acc;
      }, {}),
    [flattenStepFields]
  );

  // Set initial form values dynamically
  useEffect(() => {
    if (
      (flattenStepFieldsInitialValues && !formValues) ||
      (formValues && isEmptyObject(formValues))
    ) {
      flattenStepFieldsInitialValues &&
        dispatch(onUpdateFormData(flattenStepFieldsInitialValues));
    }
  }, [flattenStepFieldsInitialValues, dispatch, formValues]);

  // Generate Yup schema for form validation
  const flattenStepFieldsSchema = generateYupSchema(flattenStepFields);

  // Set initial values for formik
  const initialValues = flattenStepFieldsInitialValues;

  // Formik
  const formik = useFormik({
    initialValues: initialValues || {},
    validationSchema: flattenStepFieldsSchema,
    onSubmit: (_, { setSubmitting }) => {
      setSubmitting(false);
    },
  });

  const {
    errors,
    touched,
    values,
    handleSubmit,
    isSubmitting,
    setValues,
    resetForm,
  } = formik;

  const { currentStep, currentStepData, handleBack, handleContinue } =
    useForm(formik);

  const [initialFormDataLoaded, setInitialFormDataLoaded] = useState(false);

  // Check draft availability
  useEffect(() => {
    const checkDraftAvailability = async () => {
      try {
        await getDraft({
          draftUUID: state.onboardingData.draftUUID,
        });
        return true;
      } catch (error) {
        return false;
      }
    };

    const checkDraft = async () => {
      if (
        !initialFormDataLoaded &&
        loading?.formValues &&
        formValues &&
        !isEmptyObject(formValues)
      ) {
        let formValuesDefault = formValues;
        if (!referrer && formValuesDefault?.referrer && !reachedSteps) {
          formValuesDefault = {
            ...formValuesDefault,
            referrer: "",
            referredBool: "",
          };
        }

        setValues(
          (prevValues) => ({
            ...prevValues,
            ...formValuesDefault,
          }),
          false
        );

        setLoading((prevState) => ({
          ...prevState,
          formValues: false,
        }));

        setInitialFormDataLoaded(true);

        if (
          reachedSteps &&
          reachedSteps.find((step) => step > 2) &&
          !isEditingForm
        ) {
          try {
            const draftAvailable = await checkDraftAvailability();

            if (draftAvailable) {
              dispatch(onUpdateInfoStep("PENDING_REGISTRY_LOCAL"));
              dispatch(onUpdateStep("information-step"));
            } else {
              dispatch({ type: "reset-store" });
              resetForm();
              dispatch(onUpdateStep(1));
            }
          } catch {
            dispatch({ type: "reset-store" });
            resetForm();
            dispatch(onUpdateStep(1));
          }
        } else if (reachedSteps && !reachedSteps.find((step) => step === 3)) {
          resetForm();
          dispatch(onUpdateStep(1));
        }
      }
    };

    checkDraft();
  }, [loading.formValues, formValues, initialFormDataLoaded]);

  // Get required fields names from current step
  const currentStepRequiredFieldsNames = useMemo(() => {
    if (!isBasicStep(currentStepData)) return [];

    if ("fields" in currentStepData) {
      if (!currentStepData.fields) return [];

      return currentStepData?.fields
        .filter(
          ({
            validation,
            conditionalValidation,
            conditional,
            allConditionalsTrue,
          }) => {
            const requiredSchema = conditionalValidation
              ? isSchemaRequired(conditionalValidation(state))
              : isSchemaRequired(validation);

            return (
              requiredSchema &&
              (!conditional ||
                getIsConditionallyTrue(
                  values,
                  conditional,
                  allConditionalsTrue
                ))
            );
          }
        )
        .map(({ name }) => name);
    } else {
      return [];
    }
  }, [currentStepData, values]);

  const currentStepOptionalFieldsName = useMemo(() => {
    if (!isBasicStep(currentStepData)) return [];

    if ("fields" in currentStepData) {
      if (!currentStepData.fields) return [];

      return currentStepData?.fields
        .filter(
          ({
            validation,
            conditionalValidation,
            conditional,
            allConditionalsTrue,
          }) => {
            const notRequiredSchema = conditionalValidation
              ? !isSchemaRequired(conditionalValidation(state))
              : !isSchemaRequired(validation);

            return (
              notRequiredSchema &&
              (!conditional ||
                getIsConditionallyTrue(
                  values,
                  conditional,
                  allConditionalsTrue
                ))
            );
          }
        )
        .map(({ name }) => name);
    } else {
      return [];
    }
  }, [currentStepData, values]);

  const primaryButtonDisabled = () => {
    // Current Step Required Fields Have Errors
    // Current Step Required Fields Are Touched
    // Current Step Required Fields Are Not Empty

    return (
      (getKeys(errors).filter(
        (key) =>
          currentStepRequiredFieldsNames?.includes(key) ||
          (currentStepOptionalFieldsName.includes(key) &&
            !!getValue(values, key))
      ).length > 0 &&
        getKeys(touched).filter(
          (key) =>
            currentStepRequiredFieldsNames?.includes(key) ||
            currentStepOptionalFieldsName.includes(key)
        ).length > 0) ||
      !currentStepRequiredFieldsNames
        ?.map((name) => !!getValue(values, name))
        .every((value) => value) ||
      isSubmitting
    );
  };

  const secondaryButtonDisabled = () =>
    !(currentStepData?.step === 1 && currentStep?.disableButtonBack);

  // Update buttons when current step changes and when formik values change
  useEffect(() => {
    if (!currentStepData) return;

    if (!("component" in currentStepData)) {
      dispatch(
        onUpdatePrimaryButton({
          text: "Continuar",
          disabled: primaryButtonDisabled(),
          isLoading: false,
          type: "button",
          onClick: handleContinue,
          show: true,
        })
      );
      //Check if user came from the selector proyect
      const previousPageUrl = document.referrer;
      const onClickHandler =
        previousPageUrl && step === 1
          ? () => window.history.back()
          : handleBack;
      const showButton =
        step === 1 ? !!previousPageUrl : secondaryButtonDisabled();

      dispatch(
        onUpdateSecondaryButton({
          text: "Volver",
          disabled: false,
          isLoading: false,
          onClick: onClickHandler,
          show: showButton,
        })
      );

      dispatch(
        onUpdateTertiaryButton({
          text: "Volver",
          disabled: false,
          isLoading: false,
          onClick: handleBack,
          show: false,
        })
      );
    }
  }, [currentStepData, values, errors, touched, isSubmitting, step]);

  // Reorder fields
  useEffect(() => {
    if (!currentStepData || !("fields" in currentStepData)) return;

    reorderFields(currentStepData, values, setValues);
  }, [values]);

  useEffect(() => {
    const handleBeforeUnload = () =>
      logAnalyticsAndHotjarEvent("onboarding_abandoned", { step });

    window.addEventListener("beforeunload", handleBeforeUnload);

    return () => {
      window.removeEventListener("beforeunload", handleBeforeUnload);
    };
  }, []);

  useEffect(() => {
    // We iterate over all fields with errors
    Object.keys(errors).forEach((field) => {
      if (touched[field] && errors[field]) {
        // If the field has been touched and has an error, we trigger the event
        logAnalyticsAndHotjarEvent("form_error", {
          field,
          error: errors[field],
        });
      }
    });
  }, [errors, touched]);

  // Add referrer to form values if found in the URL
  useEffect(() => {
    if (referrer) {
      const newValues = {
        referredBool: "SI",
        referrer: referrer.includes("@")
          ? referrer
          : referrer
              .split("-")
              .map((name) => name.charAt(0).toUpperCase() + name.slice(1))
              .join(" "),
      };
      setValues(
        (prevValues) => ({
          ...prevValues,
          ...newValues,
        }),
        false
      );
      dispatch(onUpdateFormData(newValues));
    } else {
      // Fixing bug that keeped referrer in form values even when reloading the page
      // When no referrer is found in the URL, we reset the referrer field
      // This is done only when the user is in the first step, if not, the referrer is kept
      if (step === 1) {
        const valuesWithReferrerReseted = {
          referrer: "",
          referredBool: "",
        };

        setValues((prevValues) => ({
          ...prevValues,
          ...valuesWithReferrerReseted,
        }));
        dispatch(onUpdateFormData(valuesWithReferrerReseted));
      }
    }
  }, [referrer, loading.formValues, step]);

  return (
    <Layout hideStepper={!!currentStep?.hideStepper}>
      {loading.template || loading.formValues ? (
        <div className={loadingStyle}>
          <LoadingCircle width="50" />
        </div>
      ) : (
        <>
          <EditDNIModal formik={formik} />
          <TramitNumberModal />
          <PEPModal />
          <RiskProfileModal />
          <div className={templateContainer}>
            <Container
              className={` ${
                isComponentStep(currentStepData) &&
                currentStepData?.secondaryForm
                  ? form
                  : ""
              }`}
            >
              {isComponentStep(currentStepData) &&
              currentStepData?.component &&
              !currentStepData.insideForm ? (
                currentStepData.component(dispatch, state, formik)
              ) : (
                <FormContainer
                  icon={template?.icon}
                  currentStep={currentStep}
                  currentSubStep={Number(subStep)}
                  className={`${
                    !!isComponentStep(currentStepData) &&
                    currentStepData?.component
                      ? component
                      : ""
                  }`}
                  classNameFormContainer={`${
                    isComponentStep(currentStepData) &&
                    currentStepData?.secondaryForm
                      ? secondaryFormTemplate
                      : ""
                  }`}
                >
                  {isComponentStep(currentStepData) &&
                    currentStepData?.component &&
                    currentStepData.component(dispatch, state, formik)}

                  {initialValues &&
                    isBasicStep(currentStepData) &&
                    currentStepData?.fields && (
                      <TemplateInterpreterForm
                        onSubmit={handleSubmit}
                        templateLoaded={!!template}
                        currentStepData={currentStepData}
                        formik={formik}
                      />
                    )}
                </FormContainer>
              )}
            </Container>
            {isComponentStep(currentStepData) &&
              currentStepData?.secondaryForm && (
                <TemplateInterpreterSecondaryForm
                  {...currentStepData.secondaryForm}
                />
              )}
          </div>

          {currentStepData?.showHelpText &&
            currentStepData.showHelpText(state) && (
              <div className={helpTextContainer}>
                <p>
                  Por cualquier duda podés comunicarte con nosotros enviándonos
                  un mail a <a href={`mailto:${MAIL}`}> {MAIL}</a> o via {""}
                  <a href={WHATSAPP_LINK} target="_blank" rel="noreferrer">
                    Whatsapp
                  </a>
                </p>
              </div>
            )}

          {!currentStepData?.hideLayoutButtons && (
            <div className={buttonsGroup}>
              {tertiaryButtonData && tertiaryButtonData.show && (
                <Button
                  variant="tertiary"
                  type={tertiaryButtonData?.type}
                  onClick={tertiaryButtonData?.onClick}
                  disabled={tertiaryButtonData?.disabled}
                  className={button}
                  text={tertiaryButtonData?.text || ""}
                  isLoading={tertiaryButtonData?.isLoading}
                />
              )}

              {secondaryButtonData && secondaryButtonData.show && (
                <Button
                  variant="secondary"
                  type={secondaryButtonData?.type}
                  onClick={secondaryButtonData?.onClick}
                  className={button}
                  disabled={secondaryButtonData?.disabled}
                  text={secondaryButtonData?.text || ""}
                  isLoading={secondaryButtonData?.isLoading}
                />
              )}

              {primaryButtonData && primaryButtonData.show && (
                <Button
                  variant="primary"
                  type={primaryButtonData?.type}
                  onClick={primaryButtonData?.onClick}
                  isLoading={primaryButtonData?.isLoading}
                  disabled={primaryButtonData?.disabled}
                  text={primaryButtonData?.text || ""}
                />
              )}
            </div>
          )}
        </>
      )}
    </Layout>
  );
};

export default TemplateInterpreter;
