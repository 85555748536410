import * as Sentry from "@sentry/react";
import { DocumentData, DocumentReference, setDoc } from "firebase/firestore";
import { DniImageType, FirebaseDniData, OnboardingType } from "../../types";
import { maxFileSize, validDniTypes } from "../../utils";
import { heicToJpeg } from "../../app/utils";
import { InputFile } from "@almafintech/react-components/InputFile";
import SuccessCard from "../SuccessCard/SuccessCard";
import { showToastMessage } from "@almafintech/react-components/ToastMessage";
import { genericToastifyError } from "../../utils";
import {
  sendBusinessRepresentativeDocument,
  sendIndividualDocument,
} from "../../connectors/connectors";

import styles from "./UploadDniCard.module.scss";

const fields = [
  {
    name: "frontDNI",
    label: "Frente",
  },
  {
    name: "backDNI",
    label: "Dorso",
  },
];

interface UploadDniFormProps {
  uuid: string | null;
  onboardingType: OnboardingType;
  dniData: FirebaseDniData;
  dniRef: DocumentReference<DocumentData>;
  setError: React.Dispatch<React.SetStateAction<boolean>>;
  setIsLoadingButton: React.Dispatch<React.SetStateAction<boolean>>;
  isMobile: boolean;
  isExternalLink: boolean;
  continueWithQR: boolean;
  uploadedSuccessfully?: boolean;
  cuil: string | null;
}

const UploadDniForm = ({
  uuid,
  onboardingType,
  setError,
  setIsLoadingButton,
  dniData,
  dniRef,
  isExternalLink,
  continueWithQR,
  isMobile,
  uploadedSuccessfully,
  cuil,
}: UploadDniFormProps) => {
  const { container, mobile, externalLink, withQr, mobileInput } = styles;

  const handleOnFileUpload = async (file: File, name: DniImageType) => {
    if (!uuid) return setError(true);

    setError(false);
    setIsLoadingButton(true);

    try {
      const convertedFile = await heicToJpeg(file);

      if (onboardingType === "PHYSICAL")
        await sendIndividualDocument({
          draftUUID: uuid,
          documentType: name === "frontDNI" ? "DNI_FRONT" : "DNI_BACK",
          file: file,
          cuit: cuil || "",
        });
      else {
        await sendBusinessRepresentativeDocument({
          cuit: cuil || "",
          draftUUID: uuid,
          documentType: name === "frontDNI" ? "DNI_FRONT" : "DNI_BACK",
          file: file,
        });
      }

      saveFileToFirestore(name, convertedFile.name);
    } catch (error) {
      Sentry.captureException(error);
      setError(true);
      showToastMessage(genericToastifyError, {
        containerId: "formAlert",
        type: "error",
      });
    } finally {
      setIsLoadingButton(false);
    }
  };

  const saveFileToFirestore = (dniImage: DniImageType, fileName: string) =>
    setDoc(dniRef, { [dniImage]: fileName }, { merge: true });

  return (
    <form
      className={`${container} ${isMobile ? mobile : ""} 
      ${isExternalLink ? externalLink : ""}
      ${continueWithQR ? withQr : ""}`}
    >
      {uploadedSuccessfully ? (
        <SuccessCard
          title="¡Imágenes cargadas con éxito!"
          message="Podés cerrar esta página"
        />
      ) : (
        <>
          {continueWithQR && <h3>Cargá las fotos de tu DNI</h3>}
          {fields.map(({ name, label }, index) => (
            <InputFile
              key={name}
              name={name}
              maxSize={maxFileSize}
              validTypes={validDniTypes}
              isMobile={isMobile}
              text={
                <p>
                  Arrastrá o <span>seleccioná un archivo</span> de tu
                  dispositivo
                </p>
              }
              className={isMobile ? mobileInput : ""}
              label={label}
              infoText={`Cargá un archivo JPG, PNG, HEIC, HEIF o PDF de hasta ${maxFileSize}mb.`}
              infoTextPosition="top"
              fileData={
                dniData[name as DniImageType] !== ""
                  ? { id: index, name: dniData[name as DniImageType] }
                  : undefined
              }
              hideDownloadIcon
              onFileUpload={(file) =>
                handleOnFileUpload(file, name as DniImageType)
              }
              onFileRemove={() => {
                saveFileToFirestore(name as DniImageType, "");
              }}
            />
          ))}
        </>
      )}
    </form>
  );
};

export default UploadDniForm;
