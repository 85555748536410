import { useEffect } from "react";
import { useAppDispatch, useAppSelector } from "../../app/hooks";
import { closeModal } from "../../reducers/modalReducer";
import { Modal } from "@almafintech/react-components/Modal";
import { Button } from "@almafintech/react-components/Button";

import WarningIcon from "../../assets/images/icons/alert/warning-light.svg?react";

import styles from "./AlertModal.module.scss";

interface AlertModalProps {
  id: string;
  title: string;
  message: string;
}

const AlertModal = ({ id, title, message }: AlertModalProps) => {
  const { container } = styles;

  const dispatch = useAppDispatch();
  const { modalName, modalPrimaryButton } = useAppSelector(
    (state) => state.modal
  );

  const handleClose = () => dispatch(closeModal());

  const handlePrimaryButtonClick = () => {
    modalPrimaryButton?.action();
    handleClose();
  };

  // Close modal when component is mounted
  useEffect(() => {
    handleClose();
  }, []);

  return (
    <Modal
      isOpen={modalName === id}
      hideCloseButton
      footer={
        <div className="flex gap-4">
          <Button
            text={modalPrimaryButton?.text}
            onClick={handlePrimaryButtonClick}
            fullWidth
          />

          <Button
            text="No, volver"
            variant="secondary"
            onClick={handleClose}
            fullWidth
          />
        </div>
      }
      centerFooter
    >
      <div className={container}>
        <WarningIcon />
        <h3>{title}</h3>
        <p>{message}</p>
      </div>
    </Modal>
  );
};

export default AlertModal;
