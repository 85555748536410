import { createSlice, PayloadAction } from "@reduxjs/toolkit";

interface ModalState {
  modalOpen: boolean;
  modalName: string;
  modalPrimaryButton: {
    text: string;
    action: () => void;
  };
}

const initialState: ModalState = {
  modalOpen: false,
  modalName: "",
  modalPrimaryButton: {
    text: "",
    action: () => {},
  },
};

const modalSlice = createSlice({
  name: "modal",
  initialState,
  reducers: {
    closeModal: (state) => {
      state.modalOpen = false;
      state.modalName = "";
    },
    openModal: (state, action) => {
      state.modalOpen = true;
      state.modalName = action.payload.name;
    },
    onUpdatePrimaryButtonModal: (
      state,
      action: PayloadAction<{ text: string; action: () => void }>
    ) => {
      state.modalPrimaryButton = action.payload;
    },
  },
});

export const { closeModal, openModal, onUpdatePrimaryButtonModal } =
  modalSlice.actions;

export default modalSlice.reducer;
