import { PayloadAction, createSlice } from "@reduxjs/toolkit";

type InfoStepType =
  | "PENDING_REGISTRY_LOCAL"
  | "PENDING_REGISTRY_REMOTE"
  | "ACTIVE_ACCOUNT"
  | "REGISTERED_EMAIL"
  | null;

interface InfoStepProps {
  type: InfoStepType;
}
const initialState: InfoStepProps = {
  type: null,
};

const infoStepReducer = createSlice({
  name: "infoStep",
  initialState,
  reducers: {
    onUpdateInfoStep: (state, action: PayloadAction<InfoStepType>) => {
      state.type = action.payload;
    },
  },
});

export const { onUpdateInfoStep } = infoStepReducer.actions;

export default infoStepReducer.reducer;
