import { useEffect } from "react";
import { useSearchParams } from "react-router-dom";
import { authEmail } from "../../connectors/connectors";
import { getCompanyData } from "../../utils";
import { useAppDispatch } from "../../app/hooks";
import {
  onUpdateDraftUUID,
  onUpdateFormData,
  onUpdateStep,
} from "../../reducers/onboardingDataReducer";
import { updateSignatureRequestId } from "../../reducers/authReducer";
import { LoadingCircle } from "@almafintech/react-components";

import styles from "./SignatoryLanding.module.scss";

const SignatoryLanding = () => {
  const dispatch = useAppDispatch();

  const [searchParams] = useSearchParams();
  const externalCode = searchParams.get("externalCode");
  const email = searchParams.get("email");

  // Replace spaces with '+' if the email requires it
  const decodedEmail = email ? email.replace(/ /g, "+") : null;

  const handleSendRequest = async () => {
    try {
      if (!externalCode && !decodedEmail) return;

      const resp = await authEmail({
        email: decodedEmail || "",
        companyType: getCompanyData().companyName,
      });

      dispatch(updateSignatureRequestId(resp.data.signatureRequestId));
      dispatch(onUpdateStep(2));
    } catch (error) {
      console.log(error, "error");
    }
  };

  useEffect(() => {
    if (externalCode && decodedEmail) {
      dispatch(onUpdateDraftUUID(externalCode || ""));
      dispatch(onUpdateFormData({ email: decodedEmail }));
      handleSendRequest();
    }
  }, [externalCode, decodedEmail]);

  return (
    <div className={styles.container}>
      <LoadingCircle width="50" />
    </div>
  );
};

export default SignatoryLanding;
