import styles from "./Tabs.module.scss";

type TabOption = {
  id: string;
  label: string;
};

interface TabsProps {
  options: TabOption[];
  active: string;
  onChange: (id: string) => void;
  disabled?: boolean;
}

const Tabs = ({ options, active, onChange, disabled }: TabsProps) => {
  const { tabsContainer, disabledTabsContainer, tab, disabledTab, activeTab } =
    styles;

  const handleClick = (id: string) => {
    onChange(id);
  };

  return (
    <div
      className={`${tabsContainer} ${disabled ? disabledTabsContainer : ""}`}
    >
      {options.map(({ id, label }) => (
        <div
          className={`${tab} ${id === active ? activeTab : ""}  ${
            disabled ? disabledTab : ""
          }`}
          key={id}
          onClick={() => !disabled && id !== active && handleClick(id)}
        >
          {label}
        </div>
      ))}
    </div>
  );
};

export default Tabs;
