import { jwtDecode, JwtPayload } from "jwt-decode";
import moment from "moment";
import { DocumentBusinessType, Gender, GenderInitials } from "../types";
import heic2any from "heic2any";
import { showToastMessage } from "@almafintech/react-components";
import { customToastifyError } from "../utils";

interface OurJwtPayload extends JwtPayload {
  uuid: string;
}
export const getDraftUUIDFromToken = ({ token }: { token: string }) => {
  const decoded: OurJwtPayload = jwtDecode(token);
  const uid = decoded.uuid;

  return uid;
};

export const parseDate = (date: string) =>
  moment(date, "DD/MM/YYYY").format("YYYY-MM-DD");

export const parseBirthDate = (date: string, splitCharacter?: string) => {
  const [year, month, day] = date.split(splitCharacter ?? "-");
  return `${day}/${month}/${year}`;
};

export const parseGenderFromInitial: Record<GenderInitials, Gender> = {
  M: "MALE",
  F: "FEMALE",
};

export const parseGenderToInitial = (gender: string) => {
  if (gender === "MALE") return "M";
  if (gender === "FEMALE") return "F";
  return "X";
};

export const parseTelephone = (telephone: string): string => {
  // Validate that what we receive is '54' or '+54' followed by 10 more digits
  const regex = /^\+?54\d{10}$/;

  if (regex.test(telephone)) {
    // Remove the '+' if present and return the complete number
    return telephone.replace(/^\+/, "");
  } else {
    // Return an empty string if the format is not valid
    return "";
  }
};

export const capitalizeString = (country: string) =>
  country.charAt(0).toUpperCase() + country.slice(1).toLowerCase();

export const blobToFile = (blob: Blob, name: string, type: string) => {
  return new File([blob], name, {
    type: type,
  });
};

const heicFileTypes = ["image/heif", "image/heic", ".heic", ".heif"];

export const heicToJpeg = async (file: File): Promise<File> => {
  // HEIC TO JPEG CONVERSION
  if (heicFileTypes.includes(file.type)) {
    const newType = "image/jpeg";

    const heicBlob = await heic2any({ blob: file, toType: newType });

    if (Array.isArray(heicBlob)) return file;

    const newFileName = file.name.replace(/\.(heic|heif)$/i, ".jpg");

    const fileFromBlob = blobToFile(heicBlob, newFileName, newType);

    return fileFromBlob;
  }

  return file;
};

export const businessDocuments: Record<string, DocumentBusinessType> = {
  balance: "BALANCE",
  worthStatement: "WORTH_STATEMENT",
  sharesRegister: "SHARES_REGISTER",
  other: "OTHER",
  statute: "STATUTE",
  poa: "POA",
  authorities: "AUTHORITIES",
  membersNote: "MEMBERS_NOTE",
  shareholdersNote: "SHAREHOLDERS_NOTE",
  trustNote: "TRUST_NOTE",
  partnersNote: "PARTNERS_NOTE",
  partnersList: "PARTNERS_LIST",
  uifInscriptionCertificate: "UIF_INSCRIPTION_CERTIFICATE",
  expenseLiquidation: "EXPENSE_LIQUIDATION",
  administratorDesignationSas: "AUTHORITIES",
  administratorDesignationConsortium: "AUTHORITIES",
  horizontalPropertyRegulationsScanned: "HORIZONTAL_PROPERTY_REGULATIONS",
  trustConstitutionContract: "TRUST_CONSTITUTION_CONTRACT",
  trusteeDocumentationStatute: "STATUTE",
  trusteeDocumentationAuthorities: "AUTHORITIES",
};

export const mapDocumentBusinessType = (type: string) =>
  businessDocuments[type];

export const parseRegistrationAuthority = (authority: string) => {
  switch (authority) {
    case "OTHER":
      return "Otro";
    case "PUBLIC_REGISTRY_OF_COMMERCE":
      return "Registro Público de Comercio";
    case "GENERAL_INSPECTION_OF_JUSTICE":
      return "Inspección General de Justicia";
    default:
      return "";
  }
};

export const showCustomToastifyError = (error: string) => {
  let errorDescription = "";

  if (error === "CXU_NOT_OWNER")
    errorDescription = "La cuenta ingresada no está asociada a tu CUIT/CUIL.";
  if (error === "CXU_NOT_FOUND")
    errorDescription =
      "CBU/CVU no existente, por favor revisá los datos ingresados.";

  return showToastMessage(customToastifyError(errorDescription), {
    containerId: "formAlert",
    type: "error",
  });
};
