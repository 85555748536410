import { useAppDispatch, useAppSelector } from "../../app/hooks";
import { onUpdateSubStep } from "../../reducers/onboardingDataReducer";
import { BasicStep } from "../../types";
import styles from "./DotStep.module.scss";

interface Props {
  currentSubStep: number | null;
  subSteps: BasicStep[] | undefined;
}

const DotStep = ({ currentSubStep, subSteps }: Props) => {
  const { container, dot, filled, disabled } = styles;
  const dispatch = useAppDispatch();

  const { lastCompletedSubStep, completedSteps, step, disabledSubStep } =
    useAppSelector((state) => state.onboardingData.stepData);

  return (
    <div>
      <div className={container}>
        {currentSubStep &&
          subSteps?.map(({ step: subStep }) => {
            const isDisabled =
              (disabledSubStep && disabledSubStep === Number(subStep)) ||
              (!completedSteps?.includes(Number(step)) &&
                Number(subStep) > lastCompletedSubStep + 1);
            return (
              <button
                key={subStep}
                onClick={() => {
                  !isDisabled && dispatch(onUpdateSubStep(Number(subStep)));
                }}
              >
                <div
                  className={`${dot} ${
                    Number(subStep) <= currentSubStep ? filled : ""
                  } ${isDisabled && disabled}`}
                />
              </button>
            );
          })}
      </div>
    </div>
  );
};

export default DotStep;
