import { useEffect, useState } from "react";
import { useAppSelector } from "../../app/hooks";
import { getCompanyData, isToros, isWina } from "../../utils";
import Container from "../Container/Container";
import PreconfirmationStep from "./PreconfirmationStep/PreconfirmationStep";
import { LoadingCircle } from "@almafintech/react-components/LoadingCircle";
import useWindowWidth from "../../hooks/useWindowWidth";
import { PreconfirmationStep as PreconfirmationStepType } from "../../types";

import torosCapitalLogo from "../../assets/images/logos/toros-capital.png";
import winaLogo from "../../assets/images/logos/wina.svg";

import styles from "./PreconfirmationCard.module.scss";

const PreconfirmationCard = ({
  preconfirmationSteps,
}: {
  preconfirmationSteps: PreconfirmationStepType[];
}) => {
  const {
    container,
    header,
    stepsContainer,
    loading,
    torosLogo,
    winaLogoStyles,
    companyLogo,
    logosContainer,
  } = styles;

  const { logo, logoClassname, companyName } = getCompanyData();

  const { formValues } = useAppSelector(({ onboardingData }) => onboardingData);

  const { onboardingType } = useAppSelector(
    ({ activeTemplate }) => activeTemplate
  );

  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    if (onboardingType && formValues) setIsLoading(false);
  }, [onboardingType, formValues]);

  const dimensions = useWindowWidth();
  const isMobile = dimensions.width < 768;

  return (
    <Container className={container}>
      <div className={header}>
        <div>
          <h1>Preconfirmación de apertura</h1>
          <p>Revisá que los datos sean correctos.</p>
        </div>
        <div className={logosContainer}>
          {!isMobile && isToros(formValues) && (
            <img
              className={torosLogo}
              src={torosCapitalLogo}
              alt="Toros Capital logo"
            />
          )}
          {!isMobile && isWina(formValues) && (
            <img className={winaLogoStyles} src={winaLogo} alt="Wina logo" />
          )}
          {!isMobile && (
            <img
              className={`${companyLogo} ${
                logoClassname ? styles[logoClassname] : ""
              }`}
              src={logo}
              alt={`${companyName} logo`}
            />
          )}
        </div>
      </div>
      {isLoading ? (
        <div className={loading}>
          <LoadingCircle width="50" />
        </div>
      ) : (
        <div className={stepsContainer}>
          {preconfirmationSteps?.map(
            ({ name, columns, fields, fieldsWithSeparator, editStep }) => (
              <PreconfirmationStep
                key={name}
                name={name}
                columns={columns}
                fields={fields}
                fieldsWithSeparator={fieldsWithSeparator}
                editStep={editStep}
              />
            )
          )}
        </div>
      )}
    </Container>
  );
};

export default PreconfirmationCard;
