import { getCompanyData } from "../../utils";
import { Button } from "@almafintech/react-components/Button";
import Container from "../Container/Container";
import Layout from "../Layout/Layout";
import errorIcon from "../../assets/images/icons/ui/ui-icon-error.svg";

import styles from "./ErrorPage.module.scss";

const ErrorPage = () => {
  const { errorContainer, body, companyLogo } = styles;

  // TO DO: send email to user with new link
  const handlePrimaryButtonClick = () => {};

  return (
    <Layout hideLogo hideSupportButton>
      <Container className={errorContainer}>
        <img
          className={companyLogo}
          src={getCompanyData().logo}
          alt={`${getCompanyData().companyName} logo`}
          width={160}
        />
        <div className={body}>
          <img src={errorIcon} alt="error" />
          <h1>El enlace para el registro ya no es válido.</h1>
          <p>Solicita uno nuevo para finalizar tu registro.</p>
          <Button
            text="Solicitar nuevo enlace"
            onClick={handlePrimaryButtonClick}
          />
        </div>
      </Container>
    </Layout>
  );
};

export default ErrorPage;
