import { Signatory as SignatoryType } from "../../types";
import { useAppDispatch, useAppSelector } from "../../app/hooks";
import { onUpdateFormData } from "../../reducers/onboardingDataReducer";
import { RoundedButton } from "@almafintech/react-components/RoundedButton";
import DeleteIcon from "../../assets/images/icons/ui/delete.svg?react";
import styles from "./Signatories.module.scss";

interface SignatoryProps {
  signatory: SignatoryType;
  onClick?: () => void;
  index: number;
}

const Signatory = ({ signatory, index, onClick }: SignatoryProps) => {
  const { signatoryContainer, active, currentSignatory, icon } = styles;

  const dispatch = useAppDispatch();

  const { formValues } = useAppSelector((state) => state.onboardingData);
  const { activeIndex } = useAppSelector((state) => state.collectionData);

  const signatories = formValues?.signatories;

  const removeSignatory = () => {
    const newSignatories = [...signatories];
    newSignatories.splice(index - 1, 1);

    dispatch(onUpdateFormData({ signatories: newSignatories }));
  };

  const isActive = activeIndex === index;
  const isAnAdditionalSignatory = index !== 1;

  return (
    <div
      className={`${signatoryContainer} ${isActive && active} ${
        !isAnAdditionalSignatory && currentSignatory
      }`}
      onClick={onClick}
      id={`signatory-${index}`}
    >
      <h3>Firmante {index}</h3>
      <p>
        {signatory?.name !== "" ? signatory?.name : <span>Nombre</span>}{" "}
        {signatory?.lastName !== "" ? (
          signatory?.lastName
        ) : (
          <span>Apellido</span>
        )}{" "}
        {!isAnAdditionalSignatory && "(Tú)"}
      </p>
      {isAnAdditionalSignatory && (
        <RoundedButton
          icon={<DeleteIcon />}
          buttonType="empty"
          className={icon}
          onClick={removeSignatory}
        ></RoundedButton>
      )}
    </div>
  );
};

export default Signatory;
